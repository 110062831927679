import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useBayiTaleb() {
  const toast = useToast()
  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { label: 'Firma Adı', key: 'sFirmaAdi', sortable: true },
    { label: 'Yetkili kişi', key: 'sYetkiliKisi', sortable: true },
    { label: 'Tarihi', key: 'dtOlusTar', sortable: true },
    { label: 'Telefon', key: 'sYetkiliTelefon', sortable: true },
    { label: 'E-Posta', key: 'sYetkiliEmail', sortable: true },
    { label: 'Durum', key: 'webSiteHizmetSonucDurum', sortable: true },
    { label: 'Aksiyon', key: 'actions', class: 'text-right' },
  ]

  const perPage = ref(store.state.bayiTalebYonetim.bayiTalebLocalFilter.pageSize)
  const totalItems = ref(0)
  const currentPage = ref(store.state.bayiTalebYonetim.bayiTalebLocalFilter.pageIndex)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(store.state.bayiTalebYonetim.bayiTalebLocalFilter.search)
  const webSiteHizmetSonucDurum = ref(store.state.bayiTalebYonetim.bayiTalebLocalFilter.webSiteHizmetSonucDurum)
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  
  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })
  
  const refetchData = () => {
    refListTable.value.refresh()
  }
  
  watch([currentPage, perPage, searchQuery, webSiteHizmetSonucDurum], () => {
    store.state.bayiTalebYonetim.bayiTalebLocalFilter.pageSize = perPage;
    store.state.bayiTalebYonetim.bayiTalebLocalFilter.pageIndex = currentPage;
    store.state.bayiTalebYonetim.bayiTalebLocalFilter.search = searchQuery;
    store.state.bayiTalebYonetim.bayiTalebLocalFilter.webSiteHizmetSonucDurum = webSiteHizmetSonucDurum;
    refetchData();
  })

  const resolveStatusVariant = role => {
    if (role === 0) return 'primary'
    if (role === 1) return 'warning'
    return 'primary'
  }

  const resolveStatusName = role => {
    if (role === 0) return 'Beklemede'
    if (role === 1) return 'Görüşüldü'
    return 'Beklemede'
  }
  
  const fetchList = (ctx, callback) => {
    store.dispatch('bayiTalebYonetim/fetchBayiTalebs', {
      Search: searchQuery.value,
      PageSize: perPage.value,
      PageIndex: currentPage.value,
      sortBy: sortBy.value,
      isSortDirDesc: isSortDirDesc.value,
      webSiteHizmetSonucDurum: webSiteHizmetSonucDurum.value,
    })
    .then(response => {
      const { data, count } = response
      
      callback(data)
      totalItems.value = count
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Ancient list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    });
  }

  return {
    fetchList,
    tableColumns,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    webSiteHizmetSonucDurum,
    sortBy,
    isSortDirDesc,
    refListTable,
    resolveStatusVariant,
    resolveStatusName,
  
    refetchData,
  }
}
