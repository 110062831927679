<template>
  <div>   
    <!-- Buttons -->
    <crud-button :onClickList="fetchList" :showAddNew="false" />
    <!-- Filters -->
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <b-form-group
              label="Arama"
              label-for="Arama">
              <b-input 
                v-model="searchQuery"
                placeholder="Ara" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3"
            class="mb-md-0 mb-2">
            <label>{{ $t('Durum') }}</label>
            <v-select
              v-model="webSiteHizmetSonucDurum"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="talebDurumOptions"
              class="w-100"
              :reduce="val => val.value" />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('tane göster') }}</label>
          </b-col>
        </b-row>
        </div>
        <b-table
          ref="refListTable"
          class="position-relative"
          :items="fetchList"
          :per-page="perPage"
          :current-page="currentPage"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Hiçbir eşleşen kayıt bulunamadı"
          :sort-desc.sync="isSortDirDesc">

        <template #cell(webSiteHizmetSonucDurum)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.webSiteHizmetSonucDurum)}`"
            class="text-capitalize">
            {{ resolveStatusName(data.item.isActive) }}
          </b-badge>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <!-- Update -->
         <b-button size="sm" variant="primary" @click="update(data.item)">
            Detay
          </b-button>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12" sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">{{ dataMeta.of }} öğeden {{ dataMeta.from }} - {{ dataMeta.to }} arası gösteriliyor</span>
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12" sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end">

            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>

</template>

<script>
import StaticOptions from '@/common/options/StaticOptions'
import useBayiTaleb from './useBayiTaleb'

export default {
  components: {
  },
  data() {
    return {
      talebDurumOptions: StaticOptions.talebDurumOptions,
      modalObject: {
        item: {},
        showModal: false,
        onSuccess: this.fetchList,
      },
    }
  },
  methods: {
    update(item) {
      Object.assign(this.modalObject.item, item);
      this.modalObject.showModal = true;
    },
  },
  
  setup() {
    const {
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      webSiteHizmetSonucDurum,
      sortBy,
      isSortDirDesc,
      refListTable,
      resolveStatusVariant,
      resolveStatusName,

      refetchData,
    } = useBayiTaleb()
  
    return {
     fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      webSiteHizmetSonucDurum,
      sortBy,
      isSortDirDesc,
      refListTable,
      resolveStatusVariant,
      resolveStatusName,
      
      refetchData,
    }
  },
};
</script>

<style>
</style>
